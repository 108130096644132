'use client';
import React from 'react';
import { FieldErrors, useForm, Controller } from 'react-hook-form';
import { ValidationErrorMessage } from './ValidationErrorMessage';
import { saveEnquiry } from '@/app/services/enquiryService';
import useThemeStore from '@/app/stores/useThemeStore';
import { usePathname } from 'next/navigation';
import { useGlobalStore } from '@/app/stores/useGlobalStore';
import { cn } from '@/lib/utils';
import {
  LOCATION_IDENTIFIER_KARETRIP,
  LOCATION_IDENTIFIER_KARELINE,
  LocationKey as KarelineLocationKey,
} from '@/app/config/locations';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

type KaretripLocationKey = number;
type LocationKey = KarelineLocationKey | KaretripLocationKey;

const PLATFORM = process.env.NEXT_PUBLIC_PLATFORM;

interface FormProps {
  formType: string;
  setOpenPopup?: (isOpen: boolean) => void;
  formLabel?: string;
  cta?: string;
  buttonTitle?: string;
  contactFormbuttonTitle?: string;
  contactFormbuttonBg?: string;
}

interface EnquiryPayload {
  name: string;
  phone: string;
  message: string;
  ctaLabel: string;
  location: LocationKey;
}

const shouldShowLocation = () => {
  return PLATFORM === 'karetrip-blog' || PLATFORM === 'mykare-blog';
};

const getLocationId = (location: LocationKey): number => {
  if (!location) return 0;

  if (PLATFORM === 'karetrip-blog') {
    const locationIndex = Number(location);
    return LOCATION_IDENTIFIER_KARETRIP[locationIndex]?.id || 0;
  } else if (PLATFORM === 'mykare-blog') {
    return (
      LOCATION_IDENTIFIER_KARELINE[location as KarelineLocationKey]?.id || 0
    );
  }
  return 0;
};

function GenericForm({
  formType,
  cta = '',
  buttonTitle,
  contactFormbuttonTitle,
  setOpenPopup,
}: FormProps) {
  const { ctaLabel, setCtaLabel } = useGlobalStore();
  const { setShowToast } = useThemeStore((state) => state);

  const pathname = usePathname();

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<EnquiryPayload>();

  const onSubmit = async ({
    name,
    phone,
    message,
    location,
  }: EnquiryPayload) => {
    const categoryContext = process.env.NEXT_PUBLIC_FORM_CATEGORY_CONTEXT;
    const enquiryContext = process.env.NEXT_PUBLIC_FORM_ENQUIRY_CONTEXT;
    let payload = {
      name: name,
      sourceId: parseInt(process.env.NEXT_PUBLIC_FORM_SOURCE_ID!),
      phoneNumber: '+91' + phone,
      platform: process.env.NEXT_PUBLIC_FORM_PLATFORM,
      categoryContext: categoryContext!?.length ? categoryContext : 'ENQUIRY',
      enquiryContext: enquiryContext!?.length ? enquiryContext : null,
      notes: message,
      url: `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`,
      ctaLabel: cta,
      locationId: shouldShowLocation() ? getLocationId(location) : 0,
      device: window.innerWidth <= 768 ? 'MOBILE' : 'DESKTOP',
    };
    saveEnquiry(payload)
      .then(() => {
        reset({
          name: '',
          phone: '',
          message: '',
          ctaLabel: '',
        });
        setValue('location', '' as LocationKey);

        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
        if (setOpenPopup) {
          setOpenPopup(false);
        }
      })
      .catch((e) => console.error(e.message));
  };
  if (formType === 'CONTACT_FORM') {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputName
          errors={errors}
          restProps={{
            ...register('name', {
              required: 'Name is Required',
              pattern: {
                value: /^[A-Za-z ]+$/i,
                message: 'Name is not valid',
              },
            }),
          }}
        />
        <InputPhoneNumber
          errors={errors}
          restProps={{
            ...register('phone', {
              required: 'Phone is required',
              pattern: {
                value: /^[6-9]\d{9}$/,
                message: 'Please enter a valid Indian mobile number',
              },
              min: {
                value: 6000000000,
                message: 'Invalid Indian mobile number',
              },
              max: {
                value: 9999999999,
                message: 'Invalid Indian mobile number',
              },
            }),
          }}
        />

        {shouldShowLocation() && (
          <InputLocation errors={errors} control={control} />
        )}
        <input
          id='ctaLabel'
          type='text'
          hidden
          value={cta ? cta : ctaLabel}
          readOnly
          {...register('ctaLabel')}
        />
        <InputTextArea
          restProps={{
            ...register('message', {
              pattern: {
                value: /^[A-Za-z ]+$/i,
                message: 'Name is not valid',
              },
            }),
          }}
        />
        <button
          type='submit'
          onClick={() => setCtaLabel('Contact Form auto-popup')}
          className={cn(
            ` col-span-2 mt-[12px] h-[50px] w-full rounded-[12px] bg-[#00b4b9] text-white hover:bg-[#1e2636] focus:bg-[#1e2636] lg:bg-[#1e2636]  lg:hover:bg-[#00b4b9]`
          )}
        >
          {buttonTitle ? buttonTitle : 'Submit'}
        </button>
      </form>
    );
  }

  if (formType === 'APPOINMENT_FORM') {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='mb-[20px] mt-[42px] grid  w-[490px] grid-cols-2 gap-x-[15px] rounded-[20px] border bg-white  p-[26px]'
      >
        <div className='col-span-1'>
          <InputName
            errors={errors}
            restProps={{
              ...register('name', {
                required: 'Name is Required',
                pattern: {
                  value: /^[A-Za-z ]+$/i,
                  message: 'Name is not valid',
                },
              }),
            }}
          />
        </div>
        <div className='col-span-1'>
          <InputPhoneNumber
            errors={errors}
            restProps={{
              ...register('phone', {
                required: 'Phone is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'invalid phone number',
                },
                min: {
                  value: 1000000000,
                  message: 'Phone number is too short',
                },
                max: {
                  value: 9999999999,
                  message: 'Phone number is too long',
                },
              }),
            }}
          />
        </div>
        {shouldShowLocation() && (
          <div className='col-span-1'>
            <InputLocation errors={errors} control={control} />
          </div>
        )}
        <div className='col-span-2'>
          <InputTextArea
            restProps={{
              ...register('message', {
                pattern: {
                  value: /^[A-Za-z ]+$/i,
                  message: 'Name is not valid',
                },
              }),
            }}
          />
        </div>
        <button
          type='submit'
          className={cn(
            'col-span-2 mt-3 h-[50px] w-full rounded-[12px]  bg-[#00b4b9] text-white hover:bg-[#1e2636]  focus:bg-[#1e2636] max-xl:h-[35px] max-xl:text-[10px] lg:bg-[#1e2636] lg:hover:bg-[#00b4b9]'
          )}
        >
          {contactFormbuttonTitle ? contactFormbuttonTitle : 'Book a Callback'}
        </button>
      </form>
    );
  }
}

export default GenericForm;

const InputName = ({
  errors,
  restProps,
}: {
  errors: FieldErrors<EnquiryPayload>;
  restProps: any;
}) => {
  return (
    <>
      <input
        id='name'
        type='text'
        placeholder='Name'
        className={`max-3xl:h-[50px] h-[50px] w-full rounded-[10px] border bg-white px-[14px] placeholder:text-[black] focus:outline-none dark:border-gray-200  dark:bg-transparent max-2xl:h-[46px]  max-sm:h-[50px] lg:text-[16px]`}
        {...restProps}
      />
      <div className='flex h-[20px] items-center'>
        {errors?.name && (
          <ValidationErrorMessage>
            {errors?.name?.message}
          </ValidationErrorMessage>
        )}
      </div>
    </>
  );
};
const InputPhoneNumber = ({
  errors,
  restProps,
}: {
  errors: FieldErrors<EnquiryPayload>;
  restProps: any;
}) => {
  return (
    <>
      <div
        className={`max-3xl:h-[50px] flex h-[50px] w-full items-center justify-start rounded-[10px] border bg-white px-[14px] placeholder:text-[black] focus:outline-none dark:border-gray-200  dark:bg-transparent max-2xl:h-[46px]  max-sm:h-[50px] lg:text-[16px]`}
      >
        <p className='flex min-w-[40px] flex-nowrap text-[#000]'>+91 |</p>
        <input
          id='phone'
          type='tel'
          maxLength={10}
          placeholder='Mobile Number'
          className={`max-3xl:h-[55px] ml-[10px] h-[55px] rounded-[0_10px_10px_0] bg-transparent  text-[16px] placeholder:text-[black] focus:outline-none max-2xl:h-[55px] max-sm:h-[55px]`}
          {...restProps}
        />
      </div>
      <div className='flex h-[20px] items-center'>
        {errors?.phone && (
          <ValidationErrorMessage>
            {errors?.phone?.message}
          </ValidationErrorMessage>
        )}
      </div>
    </>
  );
};

const InputTextArea = ({ restProps }: { restProps: any }) => {
  return (
    <textarea
      id='message'
      placeholder='Send Message'
      className={` w-full  resize-none rounded-[10px] border bg-white p-[14px] placeholder:text-[black] focus:outline-none dark:border-gray-200  dark:bg-transparent lg:text-[16px]`}
      rows={3}
      {...restProps}
    />
  );
};

const InputLocation = ({
  errors,
  control,
}: {
  errors: FieldErrors<EnquiryPayload>;
  control: any;
}) => {
  return (
    <>
      <Controller
        name='location'
        control={control}
        rules={{ required: 'Location is Required' }}
        render={({ field }) => (
          <Select onValueChange={field.onChange} value={field.value}>
            <SelectTrigger
              id='location'
              className='rounded-[12px] bg-white p-3 py-[25px]'
            >
              <SelectValue placeholder='Select Location' />
            </SelectTrigger>
            <SelectContent className='border bg-white dark:border-gray-200 dark:bg-[#1e2636] max-sm:bg-white'>
              {PLATFORM === 'karetrip-blog' &&
                LOCATION_IDENTIFIER_KARETRIP.map((location, index) => (
                  <SelectItem
                    key={index}
                    value={index.toString()}
                    className='text-black hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-[#263843] dark:focus:bg-[#263843]'
                  >
                    {location.name}
                  </SelectItem>
                ))}
              {PLATFORM === 'mykare-blog' &&
                Object.entries(LOCATION_IDENTIFIER_KARELINE).map(
                  ([key, { name }]) => (
                    <SelectItem
                      key={key}
                      value={key}
                      className='text-black hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-[#263843] dark:focus:bg-[#263843]'
                    >
                      {name}
                    </SelectItem>
                  )
                )}
            </SelectContent>
          </Select>
        )}
      />
      <div className='flex h-[20px] items-center'>
        {errors?.location && (
          <ValidationErrorMessage>
            {errors?.location?.message}
          </ValidationErrorMessage>
        )}
      </div>
    </>
  );
};
