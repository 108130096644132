export const LOCATION_IDENTIFIER_KARELINE = {
  pune: { id: 1, name: 'Pune' },
  kochi: { id: 8, name: 'Kochi' },
  vizag: { id: 10, name: 'Vizag' },
  chennai: { id: 5, name: 'Chennai' },
  madurai: { id: 12, name: 'Madurai' },
  bangalore: { id: 3, name: 'Bangalore' },
  hyderabad: { id: 7, name: 'Hyderabad' },
  coimbatore: { id: 9, name: 'Coimbatore' },
  trivandrum: { id: 13, name: 'Trivandrum' },
  kolkata: { id: 6, name: 'Kolkata' },
  kozhikode: { id: 15, name: 'Kozhikode' },
  mumbai: { id: 4, name: 'Mumbai' },
} as const;

export const LOCATION_IDENTIFIER_KARETRIP = [
  {
    id: 1,
    name: 'Pune',
  },
  {
    id: 2,
    name: 'Delhi',
  },
  {
    id: 3,
    name: 'Bengaluru',
  },
  {
    id: 4,
    name: 'Mumbai',
  },
  {
    id: 5,
    name: 'Chennai',
  },
  {
    id: 6,
    name: 'Kolkata',
  },
  {
    id: 7,
    name: 'Hyderabad',
  },
  {
    id: 8,
    name: 'Kochi',
  },
  {
    id: 9,
    name: 'Tirupati',
  },
  {
    id: 10,
    name: 'Noida',
  },
  {
    id: 12,
    name: 'Gurgaon',
  },
  {
    id: 13,
    name: 'Haryana',
  },
  {
    id: 14,
    name: 'Nagpur',
  },
  {
    id: 15,
    name: 'Andhra Pradesh',
  },
  {
    id: 16,
    name: 'Ahmedabad',
  },
];

export type LocationKey = keyof typeof LOCATION_IDENTIFIER_KARELINE;
