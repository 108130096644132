import { create } from 'zustand';

interface ctaStateType {
  ctaLabel: string;
  setCtaLabel: (ctaLabel: string) => void;
  hasShownPopup: boolean;
  setHasShownPopup: (hasShown: boolean) => void;
}

export const useGlobalStore = create<ctaStateType>()((set) => ({
  ctaLabel: '',
  hasShownPopup: false,
  setCtaLabel: (ctaLabel) =>
    set((state) => ({
      ...state,
      ctaLabel,
    })),
  setHasShownPopup: (hasShownPopup) =>
    set((state) => ({
      ...state,
      hasShownPopup,
    })),
}));
