'use client';
import Image from 'next/image';
import closeBtn from '@/public/formpopup/icons/close-btn.svg';
import consultationImg from '@/public/formpopup/consultationImg.webp';
import ConsultationsIcon from '@/public/formpopup/icons/consultation.png';
import SurgeriesIcon from '@/public/formpopup/icons/surgeries.png';
import PartnerHospitalIcon from '@/public/formpopup/icons/hospitals.png';

import ModalComponent from './Common/ModalComponent';
import GenericForm from './GenericForm';

export const FormPopup = ({
  openPopup,
  setOpenPopup,
  ctaValue,
  setShowToast,
  showTreatment = true,
  imgW,
}: any) => {
  const PopList = [
    {
      id: 1,
      title: '50K+',
      content: 'Consultations',
      icon: ConsultationsIcon,
    },
    {
      id: 2,
      title: '7000+',
      content: 'Surgeries',
      icon: SurgeriesIcon,
    },
    {
      id: 3,
      title: '100+',
      content: 'Partner Hospitals',
      icon: PartnerHospitalIcon,
    },
  ];

  const usps = [
    { id: 1, title: 'Expert Doctors' },
    { id: 2, title: 'End-to-end Care' },
    { id: 3, title: 'Insurance Support' },
    { id: 4, title: 'Treatment Organiser' },
  ];

  return (
    <ModalComponent open={openPopup} setOpenPopup={setOpenPopup}>
      <section className=''>
        <section className='l absolute left-1/2 top-1/2 w-[350px] -translate-x-1/2 -translate-y-1/2 transform rounded-[20px] bg-Popup-Bg lg:w-[918px] lg:bg-white lg:bg-none'>
          <section className='hidden w-full rounded-t-[20px] bg-[#00b4b9] p-[10px] lg:flex '>
            <section className='w-full'>
              <h2
                id='modal-modal-title'
                className='text-center text-[24px] font-[500] leading-[1.96] tracking-[-0.52] text-[#fff]'
              >
                Request a call back
              </h2>
            </section>
            <section className='me-2 flex items-center'>
              <button onClick={() => setOpenPopup(false)}>
                <Image src={closeBtn} alt='clsoebtn' className='' />
              </button>
            </section>
          </section>
          <section className='flex flex-col lg:flex-row'>
            <section className='m-[20px] flex flex-col lg:m-[20px_0_0_40px] lg:w-[50%]'>
              <section className=''>
                <h3 className='text-center text-[24px] font-[600] leading-[1.36] tracking-[-0.56px] text-[#1c2534] lg:w-[70%] lg:text-left lg:text-[26px]'>
                  Find Quick Solutions for Your Concerns.
                </h3>
                <h3 className='hidden text-left text-[16px] font-[500] leading-[2.38] tracking-[-0.32px] text-[#1c2534] lg:block'>
                  Connect with experts for personalised guidance
                </h3>
              </section>
              <div className='mt-auto hidden lg:block'>
                <Image
                  src={consultationImg}
                  alt='consultationImg'
                  className={`w-[95%] w-[${imgW}]`}
                />
              </div>
            </section>
            <section className='p-[30px] lg:w-[50%] lg:p-[30px_30px_30px_0]'>
              <section className='lg:m-[30px_0_0_18px]'>
                <GenericForm
                  cta={ctaValue || 'HOMEPAGE-HEADER-BUTTON'}
                  buttonTitle='Book a Callback'
                  setOpenPopup={setOpenPopup}
                  formType='CONTACT_FORM'
                />
              </section>
            </section>
          </section>
        </section>
      </section>
    </ModalComponent>
  );
};
