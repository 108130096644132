import { formFetcher } from '@/lib/formFetcher';

export const saveEnquiry = async (postData: object) => {
  try {
    const apiKey = process.env.NEXT_PUBLIC_API_KEY;
    const response = await formFetcher({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(apiKey?.length ? { 'myk-api-key': apiKey } : {}),
      },
      body: JSON.stringify(postData),
    });

    return response;
  } catch (error) {
    console.error('===saveEnquiry error===', error);
  }
};
